import { createApp } from 'vue'
import App from './App'
import { createRouter, createWebHistory } from 'vue-router'
import VueLoading from 'vue-loading-overlay';
import VueToast from 'vue-toast-notification';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-toast-notification/dist/theme-sugar.css";
import 'bootstrap/dist/css/bootstrap.min.css'
// Create Vue
const app = createApp(App)

// Routes
const routes = [
    { path: '/', component: () => import('./screens/MainScreen'), name: 'main'},
    {path: '/internal', component: () => import('./screens/InternalScreen'), name:'internal'}
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
// Adding features
app.use(router)
app.use(VueToast);
app.use(VueLoading);
// Mounting
app.mount('#app')
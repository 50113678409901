<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.css";
export default{
    data(){
    },
}
</script> 